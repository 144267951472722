import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Popup = props => {
    let [ accepted, setAccepted ] = useState(false)
    const checkVisible = () => {
        if (typeof window !== 'undefined') {
            if (sessionStorage.getItem('popup') != null) {
                return false;
            } else {
                return true;
            }
        }
    }

    const setCookie = () => {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('popup', 'Zamknięto popup');
            setAccepted(true);
        }
    }

    const image = useStaticQuery(graphql`
      query {
        img: file(relativePath: { eq: "popup2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `)

  return (
    <>
    { checkVisible() && !accepted && 
        <div className="popup-rodo">
            <a onClick={() => { setAccepted(true); setCookie() } } className="close" href="#">X</a>
            <div className="img">
              <Img fluid={image.img.childImageSharp.fluid} />
            </div>
        </div>
    }
    </>
  )
}

export default Popup
